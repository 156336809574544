import React from "react"
import Header from "../components/header"
import FisherMap from "../components/fisher-map"
import Footer from "../components/footer"
import Explore from "../components/explore"

import story from "../images/story.jpg"
//import news1 from "../images/news1.jpg"
//import news2 from "../images/news2.jpg"

const IndexPage = () => (
  <div id="indexPage">
    <Header/>

    <FisherMap/>

    <div className="content-pad">
      <div className="columns-2 big-margin-bottom">
        <div>
          <h2 style={{textAlign: 'left', margin: '0 0 25px 0'}}>حول المشروع</h2>
          <p>

            الشبكة المتوسطية لمصائد الأسماك في البحر الأبيض المتوسط
            هو مشروع أورو متوسطي يهدف إلى تطوير FISH MED NET
            التكامل وتنويع قطاع الصيد البحري وذلك من خلال منحه أبعاد
            جديدًة. هذا المشروع بتمويل مشترك من الاتحاد الأوروبي في
            إطار برنامج التّعاون عبر الحدود لحوض البحر الأبيض المتوسط
            ENI CBC MED 2014-2020

          </p>
          <p>

            على مؤسسات الصيد البحري FISH MED NET يركز مشروع
            في البحر الأبيض المتوسط من خلال تسليط الضوء على الأهداف
            المحددة في أربعة موضوعات رئيسية، من بينها تطوير المؤسسات
            الصغيرة والمتوسطة؛ تكنولوجيا المعرفة والإبتكار والتأهيل المهني
            للشباب والنساء هي أيضًا موضوعات يتناولها هذا المشروع،
            بهدف تنشيط قطاع الصيد البحري، وجعله أكثر ملائمة وجاذبية
            للشباب، وكذلك تقديم التدريب وتسهيل الوصول إلى العمل من
            خلال الدعم المهني.

          </p>
          <p>

            بفضل الأنشطة التجارية الجديدة للمشروع يمكن للصيادين على
            أن يصبحوا حراس البحر، عبر مساهمتهم في تحقيق الهدف 14
            الحفاظ على المحيطات والبحار « : من أهداف التنمية المستدامة
            والموارد البحرية واستخدامها بشكل مستدام لتحقيق التنمية
            .» المست

          </p>
        </div>
        <img src={story}/>
      </div>

      <h2>انضم إلى مجتمع FISH MED NET</h2>
      <p>
        تهدف شبكة FISH MED NET إلى الوصول إلى أكبر عدد ممكن من مجتمعات الصيادين. نؤمن بأن التعاون بين جميع الصيادين المشاركين ضروري لتحقيق عملية ناجحة، ويمكن أن يعود بالفائدة على الجميع إذا اهتمت هذه المجتمعة بعضها ببعض. واحدة من مسؤوليات مكتب الاستشارة هي توفير المعلومات حول كيفية الانضمام إلى هذه المجتمعة وأن تكون نموذجًا للصيادين الآخرين الذين يطمحون لممارسة نشاطهم بتناغم تام مع مجتمعهم المحلي، كل ذلك في احترام أهداف التنمية المستدامة للأمم المتحدة.
        لمزيد من المعلومات حول مواقع وكيفية التواصل مع مكاتب الاستشارة، يرجى النقر <a href="/howto/">هنا</a>.
      </p>

	    <h2>التآزر مع مشاريع ENI CBC MED</h2>
      <p>
        أحد المبادئ الأساسية التي يستند عليها مشروع FISH MED NET هو إنشاء بيئة تعاونية بين أصحاب المصلحة المختلفين، وتوفير الرؤية اللازمة لأطراف المعنية للجهات الخارجية. تهدف جميع هذه الجهود إلى خلق عالم أفضل للعيش، وبالمثل، يسعى المشروع إلى التآزر مع مشاريع أخرى من ENI CBC MED التي تشترك في أهداف مماثلة. يمكنكم زيارة هذه الصفحة لعرض المشاريع المختلفة التي تتعاون مع FISH MED NET ومعرفة المزيد عن الأهداف المستدامة للأمم المتحدة المشتركة مع كل مشروع.
	    </p>
      <p><a href="/cooperation">اكتشف المزيد</a></p>

    	<h2>تدريبات FishmedNet</h2>
	    <p>
        في إطار الأنشطة التي يقوم بها مشروع FISHMEDNET، التدريب يمثل أداة رئيسية لتحسين مهارات الشركات وتطوير فرص جديدة لتنويع أنشطة الصيد.
      </p>
      <p><a href="/training">اكتشف المزيد</a></p>

    	<h2> العبوات القياسية - إيطالي</h2>
	    <p>
	يستكشف FISH MED NET أيضًا كيف يمكن للصيادين أن يصبحوا رواد أعمال من خلال تقديم الخدمات للجماعة ، انقر هنا
لمعرفة المزيد.
      </p>
      <p><a href="/standard-package">اكتشف المزيد</a></p>

      <h2>للإطلاع على آخر مستجداتنا</h2>
    </div>
    <Explore slug="index" />

    <Footer/>
  </div>
)

export default IndexPage
